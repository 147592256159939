import UnifiedNav from './unified-nav';

const unifiedConfig = {
  name: 'unifiedNav',
  class: UnifiedNav,
  querySelector: {
    login: '.identity-profile__signin',
    logout: '.identity-profile__signout',
    dashboard: '.identity-profile__menu-item--dashboard',
    email: '.identity-profile__menu-item--email',
  },
};

export default unifiedConfig;
