/* eslint-disable no-console */
import { Component } from 'js-component-framework';

import {
  identityLogin,
  identityLogout,
  identityNavOpenToggle,
  identityAuthSubscribe,
} from './utils';

import unifiedTrackClick from './tracking';

const {
  nbc: {
    identityBrand = 'default',
    identity = {},
  },
} = window;

/**
 * Component for Unified Nav Links
 */
export default class UnifiedNav extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    this.setTriggers();
  }

  setTriggers() {
    const {
      children: {
        login,
        logout,
        dashboard,
        email,
      },
      element,
    } = this;

    const {
      body,
    } = document;

    identityAuthSubscribe((status) => {
      if ('unknown' !== status) {
        document.querySelector('.identity-profile').classList.remove('identity-profile__loading');
      }

      if ('authenticated' === status) {
        body.classList.add('identity-authenticated');
      } else {
        body.classList.remove('identity-authenticated');
      }

      unifiedTrackClick('identity state change', 'identity state change');
    });

    if (element && element.classList.contains('identity-profile')) {
      element?.addEventListener('click', () => {
        identityNavOpenToggle(element);
      });
    }

    const getLinkLocation = (e, action) => {
      const parentClassName = e.target.parentElement.parentElement.className;
      const location = 'identity-profile-flyout' === parentClassName ? 'hamburger' : 'top nav';
      if ('top nav' === location && 'dashboard click' === action) {
        unifiedTrackClick('dashboard', 'identity');
      } else {
        unifiedTrackClick(location, action);
      }
    };

    const handleTrackingClick = (linkElement, linkName) => {
      linkElement.addEventListener('click', () => {
        if ('manage profile' === linkName) {
          identity.openManageProfile(identityBrand);
          unifiedTrackClick(linkName, 'dashboard click');
        } else {
          unifiedTrackClick(linkName, 'identity');
        }

        if ('email preferences' === linkName) {
          identity.openEmailPreferences(identityBrand);
        }
      });
    };

    if (login) {
      login.addEventListener('click', (e) => {
        getLinkLocation(e, 'sign in click');
        console.log('idm: attached');
        e.preventDefault();
        identityLogin();
        return false;
      });
    }

    if (logout) {
      handleTrackingClick(logout, 'sign out');
      logout.addEventListener('click', (e) => {
        e.preventDefault();
        identityLogout();
        return false;
      });
    }

    if (dashboard) {
      dashboard.addEventListener('click', (e) => {
        getLinkLocation(e, 'dashboard click');
      });
    }

    if (email) {
      handleTrackingClick(email, 'email preferences');
    }

    const manageProfile = document.querySelector('.unified--manage-profile--link');

    if (manageProfile && ! manageProfile.dataset.trackingAttached) { // sets a flag so that the event listener is only attached once
      handleTrackingClick(manageProfile, 'manage profile');
      manageProfile.dataset.trackingAttached = 'true';
    }
  }
}
