/* eslint-disable no-console */
/**
 * Initialize Unified Identity SDK
 */
import { ComponentManager } from 'js-component-framework';
import domContentLoaded from 'utils/domContentLoaded';

import unifiedConfig from '../../components/unified-identity';

import {
  identityInitialize,
} from '../../components/unified-identity/utils';

import 'scss/unified-identity/index.scss';

const manager = new ComponentManager('nbc-unified-components');

domContentLoaded(() => {
  const {
    nbc: {
      identity,
    },
  } = window;

  identityInitialize();

  identity.on('status', (status) => {
    if ('initialized' === status) {
      manager.initComponents([
        unifiedConfig,
      ]);
    }
  });
});
